import { type FC } from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const ErrorPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: '20px 15px' }}>
      <Alert message={`${t('loading')}...`} type="info" />
    </div>
  );
};

export default ErrorPage;
