import { getLanguageList } from '@/service/modules/common';
import { message } from 'antd';
import { createContext, useContext, useEffect, useState } from 'react';

interface LanguageContextProps {
  languageList: IListItem[];
}

const LanguageContext = createContext<LanguageContextProps>({
  languageList: []
});

export const LanguageProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [languageList, setLanguageList] = useState<IListItem[]>([]);
  const [messageApi, messageContextHolder] = message.useMessage();

  const getData = async () => {
    try {
      const res = await getLanguageList({
        program_name: 'smok_ground_promotion_admin'
      });
      setLanguageList(
        res.data.array.map((item: CommonObjectType) => ({
          label: item.label,
          value: item.name
        })) ?? []
      );
    } catch (error: any) {
      void messageApi.error(error.message);
    }
  };

  useEffect(() => {
    void getData();
  }, []);

  return (
    <LanguageContext.Provider value={{ languageList }}>
      {messageContextHolder}
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
