import routes from '@/route/routes';
import { type MenuRoute } from '@/route/types';
import ErrorPage from '@/pages/public/errorPage';
import { batch } from 'react-redux';
import { store } from '@/store';
import { setReloadPath, setTabs } from '@/store/slicers/tabSlice';
import { type Permission } from '@/app_models/user';
import moment from 'moment';
import type { DefaultOptionType } from 'antd/es/cascader';
import _ from 'lodash';
import { type FormInstance } from 'antd';
import Cookie from 'js-cookie';

// 密码少于6位数且全部为数字/字母，则认为是弱密码
export const weakRegex = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/;
// 是否包含特殊字符
export const hasSpecialChar = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;
// 是否为邮箱
export const isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
// 密码是否正确
export const passwordReg = /^[a-zA-Z0-9]{6,40}$/;
// 用户名是否正确
export const usernameReg = /^[a-z][a-z0-9]+$/;

/**
 * 将日期时间值格式化为指定格式的字符串形式
 * @param date 日期时间值，可以是一个 Date 对象或一个代表日期时间的字符串
 * @param format 指定的日期时间格式，默认为 'YYYY-MM-DD hh:mm:ss'
 * @returns 格式化后的日期时间字符串
 */
export function timeFormat(
  date: Date | string,
  format = 'YYYY-MM-DD HH:mm:ss'
): string {
  return date != null ? moment(new Date(date)).format(format) : date;
}

/**
 * 将日期对象格式化为指定的字符串格式
 * @param date 日期对象（可以是 Date 类型、数字类型或者字符串类型）
 * @param fmt 日期格式，支持的占位符有：年(Y)、月(M)、日(D)、小时(h)、分钟(m)、秒(s)、季度(q)、毫秒(S)
 * @returns 格式化后的日期字符串
 */
export const formatDate = <T extends number | string | Date>(
  date: T,
  fmt: string
): string => {
  if (!isEmpty(date)) return '';
  let dateTemp: Date;
  if (typeof date === 'number') {
    if (date.toString().length === 10) {
      dateTemp = new Date(date * 1000);
    } else {
      dateTemp = new Date(date);
    }
  } else if (typeof date === 'string') {
    dateTemp = new Date(date);
    if (dateTemp.toString() === 'Invalid Date') {
      dateTemp = new Date(date.replace(/-/g, '/'));
      if (dateTemp.toString() === 'Invalid Date') {
        return date;
      }
    }
  } else {
    dateTemp = date;
  }
  const o: Record<string, number> = {
    'M+': dateTemp.getMonth() + 1, // 月份
    'D+': dateTemp.getDate(), // 日
    'h+': dateTemp.getHours(), // 小时
    'm+': dateTemp.getMinutes(), // 分
    's+': dateTemp.getSeconds(), // 秒
    'q+': Math.floor((dateTemp.getMonth() + 3) / 3), // 季度
    S: dateTemp.getMilliseconds() // 毫秒
  };
  const yearRegex = /(Y+)/;
  if (yearRegex.test(fmt)) {
    const match = fmt.match(yearRegex);
    if (match != null) {
      fmt = fmt.replace(
        match[1],
        dateTemp
          .getFullYear()
          .toString()
          .slice(4 - match[1].length)
      );
    }
  }
  for (const k in o) {
    const propertyRegex = new RegExp('(' + k + ')');
    if (propertyRegex.test(fmt)) {
      const match = fmt.match(propertyRegex);
      if (match != null) {
        fmt = fmt.replace(
          match[1],
          match[1].length === 1
            ? `${o[k]}`
            : `00${o[k]}`.slice(-match[1].length)
        );
      }
    }
  }
  return fmt;
};

/**
 * 判断参数是否为空值
 * @param params 待判断的参数
 * @returns 参数为空值返回 true，否则返回 false
 */
export const isEmpty = (params: any): boolean => {
  if ([undefined, null, NaN, ''].includes(params)) return true;
  const type = Object.prototype.toString.call(params);
  if (['[object Array]', '[object Object]'].includes(type)) {
    return Object.keys(params).length === 0;
  }
  return false;
};

/**
 * 以递归的方式展平react router数组
 * @param {object[]} arr 路由数组
 * @param {string} child 需要递归的字段名
 */
export const flattenRoutes = (arr: MenuRoute[]): MenuRoute[] =>
  arr.reduce((prev: MenuRoute[], item: MenuRoute) => {
    if (Array.isArray(item.routes)) {
      prev.push(item);
    }
    return prev.concat(
      Array.isArray(item.routes) ? flattenRoutes(item.routes) : item
    );
  }, []);

/**
 * 根据路径获取路由的name和key
 * @param {string} path 路由
 */
export const getKeyName = (curPath: string = '/403'): CommonObjectType => {
  const truePath = curPath.split('?')[0];
  const curRoute = flattenRoutes(routes).find(
    (item: CommonObjectType) =>
      item.path === truePath && item.type !== 'subMenu'
  );
  if (isEmpty(curRoute)) {
    return { title: '暂无权限', tabKey: 'error', component: ErrorPage };
  }
  const { name, key, component, openKey, selectedKeys, path } =
    curRoute as MenuRoute;
  return {
    title: name,
    tabKey: key,
    component,
    openKey,
    selectedKeys,
    path
  };
};

/**
 * 页签关闭操作回调
 * @param {object} history 路由history对象。不能new新实例，不然参数无法传递
 * @param {string} returnUrl 返回地址
 * @param {function} cb 回调操作，可选
 */
export const closeTabAction = (
  navigate: any,
  returnUrl: string = '/',
  cb?: () => unknown
): void => {
  const { curTab } = store.getState().tab;
  const { pathname, search } = window.location;
  const fullpath = pathname + search;
  // 删除tab
  const tabArr = curTab.slice(); // curTab 是不可变对象. slice() 方法返回一个数组副本
  const delIndex = tabArr.findIndex((item) => item.key === fullpath);
  tabArr.splice(delIndex, 1);
  // 如果要返回的页面被关闭了，再加进去
  if (tabArr.findIndex((tab) => tab.key === returnUrl) === -1) {
    const { path } = getKeyName(returnUrl);
    tabArr.push({ path, key: returnUrl });
  }
  // 批量更新store, 同步更新ui, 避免多次绘制 UI
  // React Redux：调度多个操作时的性能注意事项. batch 已经够用, 对于高消耗的reducer操作，可以使用 以下更好的方案
  // https://medium.com/unsplash/react-redux-performance-considerations-when-dispatching-multiple-actions-5162047bf8a6
  batch(() => {
    // 储存新的tabs数组
    store.dispatch(setTabs(tabArr));
    // 刷新tab
    store.dispatch(setReloadPath(returnUrl));
    // 停止刷新tab
    setTimeout(() => {
      store.dispatch(setReloadPath('null'));
    }, 500);
  });
  if (typeof cb === 'function') {
    cb();
  } else {
    navigate(returnUrl);
  }
};

/**
 * 获取地址栏 ?参数，返回键值对对象
 */
export const getQuery = (url?: string): CommonObjectType<string> => {
  const href = url ?? window.location.href;
  const query = href.split('?');
  if (isEmpty(query[1])) return {};

  const queryArr = decodeURI(query[1]).split('&');
  const queryObj = queryArr.reduce((prev, next) => {
    const item = next.split('=');
    return { ...prev, [item[0]]: item[1] };
  }, {});
  return queryObj;
};

/**
 * 获取本地存储中的权限
 */
export const getPermission = (): Permission[] =>
  store.getState().user.UserInfo.permission ?? [];

/**
 * 根据权限判断是否有权限
 */
export const isAuthorized = (val: string): boolean => {
  const permissions = getPermission();
  return !(permissions.find((_) => _.code === val) == null);
};

/**
 * 并发执行具有限制并发数的异步任务
 * @param poolLimit 最大并发数
 * @param array 待执行的任务数组
 * @param iteratorFn 异步任务执行函数
 * @returns 所有异步任务完成后的 Promise<any>
 */
export const asyncPool = async <T>(
  poolLimit: number,
  array: T[],
  iteratorFn: (item: T, index: number) => Promise<void>
): Promise<any> => {
  const ret: Array<Promise<any>> = []; // 存储所有的异步任务
  const executing: Array<Promise<any>> = []; // 存储正在执行的异步任务
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const p = Promise.resolve().then(async () => {
      await iteratorFn(item, i);
    });
    ret.push(p); // 保存新的异步任务

    if (poolLimit <= array.length) {
      // 当任务大于池大小时，开始执行任务
      const e: Promise<any> = p.then(() =>
        executing.splice(executing.indexOf(e), 1)
      );
      executing.push(e); // 保存正在执行的异步任务
      if (executing.length >= poolLimit) {
        await Promise.race(executing); // 等待较快的任务执行完成
      }
    }
  }
  return await Promise.all(ret);
};

export function getFileExt(filename: string): string | undefined {
  const parts = filename.split(/\.(?=[^.]+$)/);
  const extension = '.' + parts[1];
  return extension;
}

export const getCountryAndStateNames = (
  countryId: string | number,
  stateId: string | number,
  countryData: CommonObjectType[]
): { countryName: string; stateName: string } => {
  if (countryData != null && countryData.length > 0) {
    const country = countryData.find(
      (country: CommonObjectType) => Number(country.id) === Number(countryId)
    );
    if (country == null) {
      // console.error(`Country with ID ${countryId} not found`);
      return { countryName: '', stateName: '' };
    }

    const state = country.state_list.find(
      (state: CommonObjectType) => Number(state.id) === stateId
    );
    if (state == null) {
      // console.error(
      //   `State with ID ${stateId} not found in country ${country.name}`
      // );
      return {
        countryName: country.name,
        stateName: ''
      };
    }

    return {
      countryName: country.name,
      stateName: state.name
    };
  } else {
    return { countryName: '', stateName: '' };
  }
};

export const getImageWithThumbnail = (url?: string) => {
  if (url == null || url === '' || url === undefined) {
    return null;
  }
  // if (url.includes(baseConfig.imageBaseUrl)) {
  //   return url + '?imageMogr2/auto-orient/thumbnail/200x200'; // 拼接thumbnail参数后的URL
  // }
  // return url;
  return url + '?imageMogr2/auto-orient/thumbnail/200x200';
};

export const enhanceImageURLWithWebP = (url?: string) => {
  // const isImageBaseUrl = url?.includes(baseConfig.imageBaseUrl) ?? false;
  const isWebpAvailable = Cookie.get('_wp') === 'available';
  if (isWebpAvailable) {
    return (url as string) + '?imageMogr2/auto-orient/format/webp'; // 拼接format参数后的URL
  }
  return url;
};

export function findObjectsByValue<
  T extends { value: string | number; children?: T[] }
>(data: T[], values: string[]): T[] {
  const result: T[] = [];
  function search(arr: T[], level: number = 0) {
    for (const item of arr) {
      if (values[level] === item.value) {
        result.push(item);
        if (item.children != null) {
          search(item.children, level + 1);
        }
        break;
      }
    }
  }

  search(data, 0);

  return result;
}

export const cascaderFilter = (
  inputValue: string,
  path: DefaultOptionType[]
) => {
  return path.some((option) =>
    (option.label as string).toLowerCase().includes(inputValue.toLowerCase())
  );
};

export const getRegionList = (item: CommonObjectType = []) => {
  return [item.country_id, item.state_id, item.city_id]
    .filter((item) => item != null && item !== '0')
    .map((item) => item.toString());
};

export const getAddressLabel = (
  item: CommonObjectType = [],
  data: IListItem[] = []
) => {
  const region = getRegionLabel(item, data) ?? '';
  return (
    (item.address as string) +
    ', ' +
    (item.address_optional !== '' && item.address_optional != null
      ? (item.address_optional as string) + ', '
      : '') +
    region
  );
};

export const getRegionLabel = (
  item: CommonObjectType = [],
  data: IListItem[] = []
) => {
  const regionList = getRegionList(item);
  const objList = findObjectsByValue<IListItem>(data, regionList);
  const values = objList.map((item) => item.label);
  return values.length > 0 ? values.slice().reverse().join(', ') : undefined;
};

export const generateListFromArray = (
  data?: CommonObjectType[]
): IListItem[] => {
  return (data ?? []).map((item: CommonObjectType) => ({
    ...item,
    label: item.name,
    value: item.id
  }));
};

export const getNameByValue = (
  value?: string | number | Array<string | number>,
  data: IListItem[] = []
) => {
  let result: string = '';
  if (value != null) {
    const values = Array.isArray(value) ? value : [value];
    const maxLevel = values?.length - 1;

    const findChildren = (
      value: Array<string | number>,
      data: IListItem[],
      level = 0
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (String(data[i].value) === String(value[level])) {
          if (level === maxLevel) {
            result = data[i].label;
            break;
          }
          const children = data[i].children;
          if (children != null) {
            findChildren(value, children, level + 1);
          }
        }
      }
    };
    findChildren(values, data);
  }
  return result;
};

export const getChildrenByValue = (
  value?: string | number | Array<string | number>,
  data: IListItem[] = []
) => {
  const result: IListItem[] = [];
  if (value != null) {
    const values = Array.isArray(value) ? value : [value];
    const maxLevel = values?.length - 1;

    const findChildren = (
      value: Array<string | number>,
      data: IListItem[],
      level = 0
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (String(data[i].value) === String(value[level])) {
          const children = data[i].children;
          if (level === maxLevel) {
            result.push(...(children ?? []));
            break;
          }
          if (children != null) {
            findChildren(value, children, level + 1);
          }
        }
      }
    };

    findChildren(values, data);
  }
  return result.slice();
};

export const copyObjectToClipboard = async (obj: any) => {
  try {
    const jsonString = JSON.stringify(obj, null, 2); // 第三个参数用于格式化输出，使其更易读
    await navigator.clipboard.writeText(jsonString);
    return true;
  } catch (err) {
    throw Error('Error copying object to clipboard');
  }
};

export const pasteObjectFromClipboard = async () => {
  try {
    const jsonString = await navigator.clipboard.readText();
    const obj = JSON.parse(jsonString);
    return obj;
  } catch (err) {
    throw Error('Error pasting object from clipboard');
  }
};

export const getGoogleLink = (record?: { lat: number; lng: number }) => {
  const lat = record?.lat?.toString();
  const lng = record?.lng?.toString();
  if (lat != null && lng != null) {
    const googleMapLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    return googleMapLink;
  } else {
    return '';
  }
};

export function getDownloadType(fileName: string) {
  const fileExtension = fileName.split('.').pop()?.toLowerCase();

  switch (fileExtension) {
    case 'pdf':
      return 'application/pdf';
    case 'doc':
    case 'docx':
      return 'application/msword';
    case 'xls':
    case 'xlsx':
      return 'application/vnd.ms-excel';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    default:
      return 'application/octet-stream';
  }
}

export const downloadFile = (res: any, defaultFilename = 'unknow') => {
  let filename = '';
  const contentDispositionHeader = res.headers['content-disposition'];
  if (contentDispositionHeader != null) {
    const regex = /filename\*=utf-8''(.*?)$/;

    const match = contentDispositionHeader.match(regex);
    if (match != null && match.length > 1) {
      filename = decodeURIComponent(match[1]);
    }
  }
  if (filename == null) {
    filename = defaultFilename;
  }

  // 创建blob对象，解析流数据
  const blob = new Blob([res.data], {
    // 设置返回的文件类型
    type: getDownloadType(filename)
  });
  // 这里就是创建一个a标签，等下用来模拟点击事件
  const a = document.createElement('a');
  // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
  const URL = window.URL ?? window.webkitURL;
  // 根据解析后的blob对象创建URL 对象
  const herf = URL.createObjectURL(blob);
  // 下载链接
  a.href = herf;
  a.download = filename;
  document.body.appendChild(a);
  // 点击a标签，进行下载
  a.click();
  // 收尾工作，在内存中移除URL 对象
  document.body.removeChild(a);
  window.URL.revokeObjectURL(herf);
};

// 1、去除前后空格
// 2、去除特殊字符
// 3、空格、连续空格变一个下划线
// 4、大写变小写
export const getAlias = (str: string) => {
  let fieldName = '';
  let previousChar = '';
  const newStr = str.trim();
  for (let i = 0; i < newStr.length; i++) {
    const char = newStr.charAt(i);
    if (
      /[a-z]/.test(char) ||
      /[\u4e00-\u9FA5]/.test(char) ||
      /[0-9]/.test(char)
    ) {
      fieldName += char;
    } else if (/[A-Z]/.test(char)) {
      fieldName += char.toLowerCase();
    } else if (char === '_') {
      fieldName += char;
    } else if (char === ' ' && previousChar !== ' ') {
      fieldName += '_';
    }

    previousChar = char;
  }
  return fieldName;
};

export function hasDuplicates(array: string[]) {
  return new Set(array).size !== array.length;
}

export const findItems = (
  checked?: Array<string | number>,
  data?: CommonObjectType[]
) => {
  if (checked == null || data == null) return [];
  let result: CommonObjectType[] = [];
  for (let i = 0; i < data.length; i++) {
    const option = data[i];
    if (Number(option.value) === Number(checked[0])) {
      result = [option, ...findItems(checked.slice(1), option.children)];
      break;
    }
  }
  return result;
};

// 查找所有上级方法
export function treeFindPath(
  tree: CommonObjectType[],
  func: (arg0: CommonObjectType) => boolean,
  path: string[] = []
) {
  if (tree == null) return [];
  for (const data of tree) {
    // 这里按照你的需求来存放最后返回的内容吧
    path.push(data.id);
    if (func(data)) return path;
    if (data.children != null) {
      const findChildren: any = treeFindPath(data.children, func, path);
      if (findChildren?.length > 0) return findChildren;
    }
    path.pop();
  }
  return [];
}

/**
 * 尝试解析 JSON 字符串
 * @param str - 要解析的 JSON 字符串
 * @returns 解析成功时返回解析后的对象，解析失败时返回包含错误信息的对象
 */
export function parse(str: string) {
  return _.attempt(JSON.parse.bind(null, str));
}

/**
 * 验证字符串是否为有效的 JSON
 * @param str - 要验证的 JSON 字符串
 * @returns 如果字符串是有效的 JSON，则返回 true，否则返回 false
 */
export function isValidJson(str: string) {
  return !_.isError(parse(str));
}

export function isNumericUsingRegex(str: string) {
  // 使用正则表达式检查字符串是否仅包含数字字符
  return !isNaN(parseFloat(str)) && isFinite(parseFloat(str));
}

export const trimFiledArrayInput = (
  { target }: any,
  form: FormInstance,
  id: string,
  index: number,
  filed?: string
) => {
  const { value } = target;
  if (isEmpty(value)) return;
  const newValues = form.getFieldValue(id);
  if (filed != null) {
    newValues[index][filed] = value.trim();
  } else {
    newValues[index] = value.trim();
  }
  form.setFieldValue(id, newValues);
};

export const trimInput = ({ target }: any, form: FormInstance) => {
  const { id, value } = target;
  if (isEmpty(value)) return;
  form.setFieldsValue({ [id]: value.trim() });
};

export const downloadUrl = (url: string, filename: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.setAttribute('target', '_blank');
  link.click();
};
