import {
  UserOutlined,
  ShopOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
  TeamOutlined,
  EnvironmentOutlined,
  SettingOutlined,
  GlobalOutlined,
  QuestionCircleOutlined,
  GroupOutlined,
  AppstoreOutlined,
  CloudOutlined,
  SolutionOutlined,
  PartitionOutlined,
  InteractionOutlined
} from '@ant-design/icons';
import { type MenuRoute } from '@/route/types';
import React, { lazy, Suspense, type FC } from 'react';
import Loading from '@/pages/public/loading';
import { ErrorBoundary } from 'react-error-boundary';

type Importer<T extends React.ComponentType<any>> = () => Promise<{
  default: T;
}>;

function lazyLoad<T extends React.ComponentType<any>>(
  importer: Importer<T>
): FC<React.ComponentPropsWithoutRef<T>> {
  const Component: any = lazy(importer);

  const LazyLoadWrapper: FC<React.ComponentPropsWithoutRef<T>> = (props) => {
    return (
      <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}>
        <Suspense fallback={<Loading />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };

  return LazyLoadWrapper;
}

const Home = lazyLoad(async () => await import('@/pages/home'));
const PermissionAdminList = lazyLoad(
  async () => await import('@/pages/permission/admin/list')
);
const PermissionAdminEdit = lazyLoad(
  async () => await import('@/pages/permission/admin/edit')
);
const PermissionRoleList = lazyLoad(
  async () => await import('@/pages/permission/role/list')
);
const PermissionRoleEdit = lazyLoad(
  async () => await import('@/pages/permission/role/edit')
);
const PermissionAuthList = lazyLoad(
  async () => await import('@/pages/permission/auth/list')
);
const PermissionAuthEdit = lazyLoad(
  async () => await import('@/pages/permission/auth/edit')
);
const PermissionAuthCategoryList = lazyLoad(
  async () => await import('@/pages/permission/authCategory/list')
);
const PermissionAuthCategoryEdit = lazyLoad(
  async () => await import('@/pages/permission/authCategory/edit')
);
const ConfigList = lazyLoad(async () => await import('@/pages/config/list'));
const ConfigEdit = lazyLoad(async () => await import('@/pages/config/edit'));
const File = lazyLoad(async () => await import('@/pages/file'));
const StoreStatistics = lazyLoad(
  async () => await import('@/pages/statistics')
);
const StoreList = lazyLoad(async () => await import('@/pages/store/list'));
const StoreEdit = lazyLoad(async () => await import('@/pages/store/edit'));
const StoreAdd = lazyLoad(async () => await import('@/pages/store/edit'));
const StoreTypeList = lazyLoad(
  async () => await import('@/pages/storeType/list')
);
const StoreRankList = lazyLoad(
  async () => await import('@/pages/storeRank/list')
);
const UploadStoreList = lazyLoad(
  async () => await import('@/pages/uploadStore/list')
);
const UploadStoreEdit = lazyLoad(
  async () => await import('@/pages/uploadStore/edit')
);
const PromoterList = lazyLoad(
  async () => await import('@/pages/promoter/list')
);
const PromoterEdit = lazyLoad(
  async () => await import('@/pages/promoter/edit')
);
const PromoterStatistics = lazyLoad(
  async () => await import('@/pages/statistics')
);
const CheckInList = lazyLoad(async () => await import('@/pages/checkIn/list'));
const CheckInCharts = lazyLoad(
  async () => await import('@/pages/checkIn/charts')
);
const CountryList = lazyLoad(
  async () => await import('@/pages/region/country/list')
);
const StateList = lazyLoad(
  async () => await import('@/pages/region/state/list')
);
const CityList = lazyLoad(async () => await import('@/pages/region/city/list'));
const QuestionnaireList = lazyLoad(
  async () => await import('@/pages/questionnaire/list')
);
const EditQuestionnaire = lazyLoad(
  async () => await import('@/pages/questionnaire/edit')
);
const EditQuestion = lazyLoad(
  async () => await import('@/pages/questionnaire/edit/question/edit')
);
const AnswerList = lazyLoad(
  async () => await import('@/pages/questionnaire/answer/list')
);
const GroupList = lazyLoad(async () => await import('@/pages/group/list'));
const AppVersionList = lazyLoad(
  async () => await import('@/pages/appVersion/list')
);
const QiniuNodeList = lazyLoad(
  async () => await import('@/pages/qiniu/node/list')
);
const QiniuNodeGroupList = lazyLoad(
  async () => await import('@/pages/qiniu/node/group')
);
const ErrorPage = lazyLoad(
  async () => await import('@/pages/public/errorPage')
);
const SupplierList = lazyLoad(
  async () => await import('@/pages/supplier/list')
);
const ChainStoreList = lazyLoad(
  async () => await import('@/pages/chainStore/list')
);
const i18nProgramList = lazyLoad(
  async () => await import('@/pages/i18n/program')
);
const i18nLanguageList = lazyLoad(
  async () => await import('@/pages/i18n/language')
);
const i18nModuleList = lazyLoad(
  async () => await import('@/pages/i18n/module')
);
const i18nLocaleList = lazyLoad(
  async () => await import('@/pages/i18n/locale')
);
const i18nFieldList = lazyLoad(async () => await import('@/pages/i18n/field'));
const i18nContentList = lazyLoad(
  async () => await import('@/pages/i18n/content')
);
const i18nLanguageMangerList = lazyLoad(
  async () => await import('@/pages/i18n/language/manager')
);

/**
 * path 跳转的路径
 * component 对应路径显示的组件
 * exact 匹配规则，true的时候则精确匹配。
 * hideInMenu 是否在menu中展示
 * subMenu是否有子菜单
 * openKey 始展开的 SubMenu 菜单项 key 数组
 * selectedKeys 选中的菜单项 key 数组
 * key 唯一标识当前路由配置的属性，用于标识和管理路由项、menu的key规则
 * noAuth 不需要权限
 */
const preDefineRoutes: MenuRoute[] = [
  {
    path: '/',
    name: 'menu:blankPage',
    exact: true,
    key: 'home',
    hideInMenu: true,
    component: Home
  },
  {
    path: '/permission',
    name: 'menu:permissionManagement',
    key: 'permission',
    identifier: '/root/auth',
    type: 'subMenu',
    icon: <UserOutlined />,
    openKey: ['permission'],
    routes: [
      {
        path: '/permission/admin/list',
        name: 'menu:adminList',
        exact: true,
        key: 'permission:admin:list:view', // 子菜单项的 key使用了主菜单的 key 作为前缀，以确保它们与特定的主菜单项关联起来
        openKey: ['permission'],
        component: PermissionAdminList
      },
      {
        path: '/permission/admin/add',
        name: '添加管理员',
        exact: true,
        hideInMenu: true,
        key: 'permission:admin:list:add',
        selectedKeys: ['permission:admin:list:view'],
        openKey: ['permission'],
        component: PermissionAdminEdit
      },
      {
        path: '/permission/admin/edit',
        name: '编辑管理员',
        exact: true,
        hideInMenu: true,
        key: 'permission:admin:list:edit',
        selectedKeys: ['permission:admin:list:view'],
        openKey: ['permission'],
        component: PermissionAdminEdit
      },
      {
        path: '/permission/role/list',
        name: 'menu:roleList',
        exact: true,
        key: 'permission:role:list:view',
        openKey: ['permission'],
        component: PermissionRoleList
      },
      {
        path: '/permission/role/add',
        name: '添加角色',
        exact: true,
        hideInMenu: true,
        key: 'permission:role:list:add',
        selectedKeys: ['permission:role:list:view'],
        openKey: ['permission'],
        component: PermissionRoleEdit
      },
      {
        path: '/permission/role/edit',
        name: '编辑角色',
        exact: true,
        hideInMenu: true,
        key: 'permission:role:list:edit',
        selectedKeys: ['permission:role:list:view'],
        openKey: ['permission'],
        component: PermissionRoleEdit
      },
      {
        path: '/permission/auth/list',
        name: 'menu:permissionList',
        exact: true,
        key: 'permission:auth:list:view',
        openKey: ['permission'],
        component: PermissionAuthList
      },
      {
        path: '/permission/auth/add',
        name: '添加权限',
        exact: true,
        hideInMenu: true,
        key: 'permission:auth:list:add',
        selectedKeys: ['permission:auth:list:view'],
        openKey: ['permission'],
        component: PermissionAuthEdit
      },
      {
        path: '/permission/auth/edit',
        name: '编辑权限',
        exact: true,
        hideInMenu: true,
        key: 'permission:auth:list:edit',
        selectedKeys: ['permission:auth:list:view'],
        openKey: ['permission'],
        component: PermissionAuthEdit
      },
      {
        path: '/permission/auth-category/list',
        name: 'menu:permissionCategory',
        exact: true,
        key: 'permission:authcategory:list:view',
        openKey: ['permission'],
        component: PermissionAuthCategoryList
      },
      {
        path: '/permission/auth-category/add',
        name: '添加权限分类',
        exact: true,
        hideInMenu: true,
        key: 'permission:authcategory:list:add',
        selectedKeys: ['permission:authcategory:list:view'],
        openKey: ['permission'],
        component: PermissionAuthCategoryEdit
      },
      {
        path: '/permission/auth-category/edit',
        name: '编辑权限分类',
        exact: true,
        hideInMenu: true,
        key: 'permission:authcategory:list:edit',
        selectedKeys: ['permission:authcategory:list:view'],
        openKey: ['permission'],
        component: PermissionAuthCategoryEdit
      }
    ]
  },
  {
    path: '/config/list',
    name: 'menu:configManagement',
    key: 'config',
    identifier: '/web/config',
    icon: <SettingOutlined />,
    component: ConfigList,
    routes: [
      {
        path: '/config/edit',
        name: 'menu:editConfig',
        exact: true,
        key: 'config:edit',
        hideInMenu: true,
        selectedKeys: ['config'],
        component: ConfigEdit
      },
      {
        path: '/config/add',
        name: 'menu:addConfig',
        exact: true,
        key: 'config:add',
        hideInMenu: true,
        selectedKeys: ['config'],
        component: ConfigEdit
      }
    ]
  },
  {
    path: '/app/version/list',
    name: 'menu:appVersionManagement',
    key: 'app:version',
    identifier: '/app/version',
    icon: <AppstoreOutlined />,
    component: AppVersionList
  },
  {
    path: '/group/list',
    name: 'menu:groupManagement',
    key: 'group',
    identifier: '/group',
    icon: <GroupOutlined />,
    component: GroupList
  },
  {
    path: '/store',
    name: 'menu:storeManagement',
    key: 'store',
    identifier: '/store',
    type: 'subMenu',
    icon: <ShopOutlined />,
    openKey: ['store'],
    routes: [
      {
        path: '/store/statistics',
        name: 'menu:storeStatistics',
        exact: true,
        key: 'store:statistics',
        openKey: ['store'],
        component: StoreStatistics
      },
      {
        path: '/store/list',
        name: 'menu:storeList',
        exact: true,
        key: 'store:list',
        openKey: ['store'],
        component: StoreList
      },
      {
        path: '/store/edit',
        name: 'menu:editStore',
        exact: true,
        key: 'store:list:edit',
        component: StoreEdit,
        hideInMenu: true,
        openKey: ['store'],
        selectedKeys: ['store:list']
      },
      {
        path: '/store/add',
        name: 'menu:addStore',
        exact: true,
        key: 'store:list:add',
        component: StoreAdd,
        hideInMenu: true,
        openKey: ['store'],
        selectedKeys: ['store:list']
      },
      {
        path: '/store/upload/list',
        name: 'menu:storeUploadRecords',
        exact: true,
        key: 'store:upload:list',
        openKey: ['store'],
        component: UploadStoreList
      },
      {
        path: '/store/upload/edit',
        name: 'menu:storeUploadAudit',
        exact: true,
        key: 'store:upload:edit',
        component: UploadStoreEdit,
        hideInMenu: true,
        openKey: ['store'],
        selectedKeys: ['store:upload:list']
      },
      {
        path: '/store/upload/detail',
        name: 'menu:storeUploadDetail',
        exact: true,
        key: 'store:upload:detail',
        component: UploadStoreEdit,
        hideInMenu: true,
        openKey: ['store'],
        selectedKeys: ['store:upload:list']
      },
      {
        path: '/store/type/list',
        name: 'menu:storeTypeList',
        exact: true,
        key: 'store:type:list',
        openKey: ['store'],
        component: StoreTypeList
      },
      {
        path: '/store/rank/list',
        name: 'menu:storeRankList',
        exact: true,
        key: 'store:rank:list',
        openKey: ['store'],
        component: StoreRankList
      }
    ]
  },
  {
    path: '/supplier',
    name: 'menu:supplierManagement',
    key: 'supplier',
    identifier: '/supplier',
    type: 'subMenu',
    icon: <SolutionOutlined />,
    openKey: ['supplier'],
    routes: [
      {
        path: '/supplier/list',
        name: 'menu:supplierList',
        exact: true,
        key: 'supplier:list',
        openKey: ['supplier'],
        component: SupplierList
      }
    ]
  },
  {
    path: '/chain_store',
    name: 'menu:chainStoreManagement',
    key: 'chainStore',
    identifier: '/chain_store',
    type: 'subMenu',
    icon: <PartitionOutlined />,
    openKey: ['chainStore'],
    routes: [
      {
        path: '/chain_store/list',
        name: 'menu:chainStoreList',
        exact: true,
        key: 'chainStore:list',
        openKey: ['chainStore'],
        component: ChainStoreList
      }
    ]
  },
  {
    path: '/promoter',
    name: 'menu:promoterManagement',
    key: 'promoter',
    identifier: '/promoter',
    type: 'subMenu',
    icon: <TeamOutlined />,
    openKey: ['promoter'],
    routes: [
      {
        path: '/promoter/statistics',
        name: 'menu:promoterStatistics',
        exact: true,
        key: 'promoter:statistics',
        openKey: ['promoter'],
        component: PromoterStatistics
      },
      {
        path: '/promoter/list',
        name: 'menu:promoterList',
        exact: true,
        key: 'promoter:list',
        openKey: ['promoter'],
        component: PromoterList
      },
      {
        path: '/promoter/edit',
        name: 'menu:editPromoter',
        exact: true,
        key: 'promoter:edit',
        component: PromoterEdit,
        openKey: ['promoter'],
        selectedKeys: ['promoter:list'],
        hideInMenu: true
      },
      {
        path: '/promoter/add',
        name: 'menu:addPromoter',
        exact: true,
        key: 'promoter:add',
        component: PromoterEdit,
        openKey: ['promoter'],
        selectedKeys: ['promoter:list'],
        hideInMenu: true
      }
    ]
  },
  {
    path: '/check-in',
    name: 'menu:checkInManagement',
    key: 'checkIn',
    identifier: '/clock_in',
    type: 'subMenu',
    icon: <EnvironmentOutlined />,
    openKey: ['checkIn'],
    routes: [
      {
        path: '/check-in/list',
        name: 'menu:checkInList',
        exact: true,
        key: 'checkIn:list',
        openKey: ['checkIn'],
        component: CheckInList
      },
      {
        path: '/check-in/charts',
        name: 'menu:checkInStatistics',
        exact: true,
        key: 'checkIn:charts',
        openKey: ['checkIn'],
        component: CheckInCharts
      }
    ]
  },

  {
    path: '/questionnaire/list',
    name: 'menu:questionnaireManagement',
    key: 'questionnaire',
    identifier: '/questionnaire',
    icon: <QuestionCircleOutlined />,
    component: QuestionnaireList,
    routes: [
      {
        path: '/questionnaire/add',
        name: 'menu:addQuestionnaire',
        exact: true,
        key: 'questionnaire:add',
        hideInMenu: true,
        selectedKeys: ['questionnaire'],
        component: EditQuestionnaire
      },
      {
        path: '/questionnaire/edit',
        name: 'menu:editQuestionnaire',
        exact: true,
        key: 'questionnaire:edit',
        hideInMenu: true,
        selectedKeys: ['questionnaire'],
        component: EditQuestionnaire
      },
      {
        path: '/questionnaire/question/add',
        name: 'menu:addQuestion',
        exact: true,
        key: 'questionnaire:question:add',
        hideInMenu: true,
        selectedKeys: ['questionnaire'],
        component: EditQuestion
      },
      {
        path: '/questionnaire/question/edit',
        name: 'menu:editQuestion',
        exact: true,
        key: 'questionnaire:question:edit',
        hideInMenu: true,
        selectedKeys: ['questionnaire'],
        component: EditQuestion
      },
      {
        path: '/questionnaire/sub_question/add',
        name: 'menu:addSubQuestion',
        exact: true,
        key: 'questionnaire:sub_question:add',
        hideInMenu: true,
        selectedKeys: ['questionnaire'],
        component: EditQuestion
      },
      {
        path: '/questionnaire/sub_question/edit',
        name: 'menu:editSubQuestion',
        exact: true,
        key: 'questionnaire:sub_question:edit',
        hideInMenu: true,
        selectedKeys: ['questionnaire'],
        component: EditQuestion
      },
      {
        path: '/questionnaire/answer/list',
        name: 'menu:viewAnswer',
        exact: true,
        key: 'questionnaire:answer:list',
        hideInMenu: true,
        selectedKeys: ['questionnaire'],
        component: AnswerList
      }
    ]
  },
  {
    path: '/file',
    name: 'menu:fileManagement',
    key: 'file',
    identifier: '/file',
    icon: <FolderOpenOutlined />,
    openKey: ['file'],
    component: File
  },
  {
    path: '/qiniu',
    name: 'menu:qiniuManagement',
    key: 'qiniu',
    identifier: '/qiniu',
    type: 'subMenu',
    icon: <CloudOutlined />,
    openKey: ['qiniu'],
    routes: [
      {
        path: '/qiniu/node/list',
        name: 'menu:nodeList',
        exact: true,
        key: 'qiniu:node:list',
        openKey: ['qiniu'],
        component: QiniuNodeList
      },
      {
        path: '/qiniu/node/group',
        name: 'menu:nodeAllocation',
        exact: true,
        key: 'qiniu:node:group',
        openKey: ['qiniu'],
        component: QiniuNodeGroupList
      }
    ]
  },
  {
    path: '/region',
    name: 'menu:regionManagement',
    key: 'region',
    identifier: '/address',
    type: 'subMenu',
    icon: <GlobalOutlined />,
    openKey: ['region'],
    routes: [
      {
        path: '/coutry/list',
        name: 'menu:countryList',
        exact: true,
        key: 'region:coutry:list',
        openKey: ['region'],
        component: CountryList
      },
      {
        path: '/state/list',
        name: 'menu:stateList',
        exact: true,
        key: 'region:state:list',
        openKey: ['region'],
        component: StateList
      },
      {
        path: '/city/list',
        name: 'menu:cityList',
        exact: true,
        key: 'region:city:list',
        openKey: ['region'],
        component: CityList
      }
    ]
  },
  {
    path: '/i18n',
    name: 'menu:i18nManagement',
    key: 'i18n',
    identifier: '/i18n',
    type: 'subMenu',
    icon: <InteractionOutlined />,
    openKey: ['i18n'],
    routes: [
      {
        path: '/i18n/language/list',
        name: 'menu:languageList',
        exact: true,
        key: 'i18n:language:list',
        openKey: ['i18n'],
        component: i18nLanguageList
      },
      {
        path: '/i18n/program/list',
        name: 'menu:programList',
        exact: true,
        key: 'i18n:program:list',
        openKey: ['i18n'],
        component: i18nProgramList
      },
      {
        path: '/i18n/field/list',
        name: 'menu:fieldList',
        exact: true,
        key: 'i18n:field:list',
        openKey: ['i18n'],
        component: i18nFieldList
      },
      {
        path: '/i18n/locale/list',
        name: 'menu:localeList',
        exact: true,
        key: 'i18n:locale:list',
        openKey: ['i18n'],
        component: i18nLocaleList
      },
      {
        path: '/i18n/module/list',
        name: 'menu:moduleList',
        exact: true,
        key: 'i18n:module:list',
        openKey: ['i18n'],
        component: i18nModuleList
      },
      {
        path: '/i18n/content/list',
        name: 'menu:contentList',
        exact: true,
        key: 'i18n:content:list',
        openKey: ['i18n'],
        component: i18nContentList
      },
      {
        path: '/i18n/language/manager/list',
        name: 'menu:languageManager',
        exact: true,
        key: 'i18n:language:auth:list',
        openKey: ['i18n'],
        component: i18nLanguageMangerList,
        hideInMenu: true
      }
    ]
  },
  {
    path: '/403',
    name: 'menu:noPermission',
    exact: true,
    key: 'error',
    icon: <InfoCircleOutlined />,
    hideInMenu: true,
    component: ErrorPage
  }
];

export default preDefineRoutes;
