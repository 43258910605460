type ConfigType = Record<
  string,
  {
    apiPath: string;
    imageBaseUrl: string;
    uploadBaseUrl: string;
    h5BaseUrl: string;
    i18nPath: string;
    i18nbasePath: string;
    [propName: string]: any;
  }
>;

const config: ConfigType = {
  local: {
    apiPath: 'http://192.168.192.157:22661',
    imageBaseUrl: 'https://res.smoktech.com',
    h5BaseUrl: 'http://192.168.196.200:22692',
    uploadBaseUrl: 'ground/test/v2/',
    i18nPath: '/i18nApiPath/i18n_dir/smok_ground_promotion_admin/locales',
    i18nbasePath: '/i18nApiPath'
  },
  test: {
    apiPath: 'http://192.168.192.157:22661',
    imageBaseUrl: 'https://res.smoktech.com',
    h5BaseUrl: 'http://192.168.192.157:22692',
    uploadBaseUrl: 'ground/test/v2/',
    i18nPath:
      'http://192.168.192.157:22691/i18n_dir/smok_ground_promotion_admin/locales',
    i18nbasePath: 'http://192.168.192.157:22691'
  },
  prod: {
    apiPath: 'https://admin.ground.smoktech.com',
    imageBaseUrl: 'https://res.smoktech.com',
    h5BaseUrl: 'https://h5.ground.smoktech.com',
    uploadBaseUrl: 'ground/files/v1',
    i18nPath:
      'https://admin.ground.smoktech.com/i18n_dir/smok_ground_promotion_admin/locales',
    i18nbasePath: 'https://admin.ground.smoktech.com'
  }
};

const type = process.env.REACT_APP_TYPE ?? 'local';

export default config[type];
