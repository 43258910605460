import { type FC } from 'react';
import { Alert } from 'antd';

const ErrorPage: FC = () => {
  return (
    <div style={{ padding: '20px 15px' }}>
      <Alert message="403" description="暂无权限" type="error" showIcon />
    </div>
  );
};

export default ErrorPage;
